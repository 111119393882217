<template>
   <YLayoutBodyMenu :menu="[
       {title:'基础配置',path:'/shop/plugin/knowledge/conf'},
       {title:'课程管理',path:'/shop/plugin/knowledge/course'},
       {title:'课程分类',path:'/shop/plugin/knowledge/cate'},
       {title:'讲师管理',path:'/shop/plugin/knowledge/teacher'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>